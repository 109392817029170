<template>
  <div class='alarm_list'>
    <el-row class="ranking_title">
      <el-col :span="4">排名</el-col>
      <el-col :span="8">单位</el-col>
      <el-col :span="6">设备数量</el-col>
      <el-col :span="6">告警总量</el-col>
    </el-row>
    <div class="swiper swiper_ranking" v-if="rankingList.length>0">
      <div class="swiper-wrapper">
        <el-row v-for="(el, index) in rankingList" class="swiper-slide" :key="index">
          <el-col :span="4">{{index + 1}}</el-col>
          <el-col :span="8">
            <p>{{el.unit}}</p>
          </el-col>
          <el-col :span="6">
            <p>{{el.deviceNum}}</p>
          </el-col>
          <el-col :span="6">
            <p>{{el.eventNum}}</p>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getUnitRankList } from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default {
  data () {
    return {
      rankingList: []
    }
  },
  created () {

  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      getUnitRankList().then(res => {
        this.rankingList = res.data
        this.initSwiper()
      })
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.rankingList && this.rankingList.length > 5) {
          new Swiper('.swiper_ranking', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.rankingList && this.rankingList.length <= 5) {
          new Swiper('.swiper_ranking', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.alarm_list {
  width: 438px;
  margin: 29px 0 0 33px;
  // background: rgba(4, 32, 49, 0.4);
  .ranking_title {
    padding: 8px 0;
    font-size: 16px;
    display: flex;
    color: #c5d0d4;
    background: rgba(236, 244, 255, 0.1);
    .el-col {
      float: none;
      text-align: center;
    }
  }
  .swiper_ranking {
    overflow: hidden;
    height: 220px;
    margin-top: 25px;
    .swiper-slide {
      display: flex;
      margin-bottom: 32px;
      height: 15px;
      .el-col {
        float: none;
        font-size: 14px;
        color: #c5d0d4;
        text-align: center;
        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  // }
}
</style>
