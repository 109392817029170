<template>
  <ul class='left_title'>
    <li>
      <p>
        <img src="@/assets/smoke/unit.png" alt="">
      </p>
      <div>
        <h4>
          <countTo :startVal='0' :endVal='totalQuantity.unitNum' :duration='2000' separator="," />个
        </h4>
        <p>单位总量</p>
      </div>
    </li>
    <li>
      <p>
        <img src="@/assets/smoke/device.png" alt="">
      </p>
      <div>
        <h4>
          <countTo :startVal='0' :endVal='totalQuantity.deviceNum' :duration='2000' separator="," />个
        </h4>
        <p>设备总量</p>
      </div>
    </li>
    <li>
      <p>
        <img src="@/assets/smoke/alarm.png" alt="">
      </p>
      <div>
        <h4>
          <countTo :startVal='0' :endVal='totalQuantity.eventNum' :duration='2000' separator="," />次
        </h4>
        <p>告警总量</p>
      </div>
    </li>
    <li>
      <p>
        <img src="@/assets/smoke/guard.png" alt="">
      </p>
      <div>
        <h4>
          <countTo :startVal='0' :endVal='totalQuantity.dateNum' :duration='2000' separator="," />天
        </h4>
        <p>已守护{{name}}人民健康</p>
      </div>
    </li>
  </ul>
</template>

<script>
import countTo from 'vue-count-to'
import { getSmokeTotal } from '@/api'
import { mapGetters } from 'vuex'
export default {
  components: { countTo },
  data () {
    return {
      totalQuantity: {}
    };
  },
  created () {

  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapGetters(['name'])
  },
  methods: {
    init () {
      getSmokeTotal().then(res => {
        this.totalQuantity = res.data
      })
    }
  }
};
</script>

<style scoped lang="scss">
.left_title {
  display: flex;
  margin-top: 28px;
  li {
    margin-right: 36px;
    background: url("../../../../assets/smoke/title_bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 19px 0 19px 26px;
    width: 310px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    > div {
      margin-left: 45px;
      font-size: 19px;
      color: rgba(249, 250, 250, 0.4);
      line-height: 21px;
      // opacity: 0.4;
      h4 {
        margin-bottom: 17px;
        span {
          font-size: 36px;
          color: #00f0ff;
          opacity: 1;
        }
      }
      p {
        font-weight: 600;
      }
    }
  }
  li:last-child {
    margin-right: 0;
    padding-left: 32px;
  }
}
</style>
