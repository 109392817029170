<template>
  <div class='alarm_today'>
    <p class="smoke_title">今日告警数分布</p>
    <div id="today_pie"></div>
  </div>
</template>

<script>
import { getTodayAlarm } from '@/api'
export default {
  data () {
    return {
      chart: null,
      chartArr: [
      ],
      todayInterval: null,
      dataIndex: 0
    };
  },
  created () {

  },
  mounted () {
    this.init()

  },
  methods: {
    init () {
      getTodayAlarm().then(res => {
        const arr = res.data.filter(el => el.info !== '全部')
        arr.map(el => {
          this.chartArr.push({
            name: el.info,
            value: el.alarmNum
          })
        })
        this.$nextTick(() => {
          this.drawEcharts()
        })
      })
    },
    drawEcharts () {
      this.chart = this.$echarts.init(document.getElementById('today_pie'))
      const option = {
        legend: {
          data: this.chartArr,
          textStyle: {
            color: '#fff'
          },
          orient: "vertical",
          top: '10%',
          right: '10%',
          icon: "rect",
          itemWidth: 15,
          itemHeight: 15,
          // itemGap: 27.5
        },
        series: [
          {
            name: "",
            type: 'gauge',
            min: 0,
            max: 100,
            radius: '100%', //图表尺寸
            center: ['30%', '50%'],
            startAngle: 90,
            endAngle: -269.9999,
            axisLine: {
              show: false,
              lineStyle: {
                width: 0,
                shadowBlur: 0,
                color: [
                  [1, '#0dc2fe']
                ]
              }
            },
            axisTick: {
              splitNumber: 6,
              lineStyle: {
                color: '#0481C3',
                shadowBlur: 20,
                shadowColor: 'rgba(3, 98, 121, 1)'
              }
            },
            splitLine: {
              show: true,
              length: 10,
              distance: 10,
              lineStyle: {
                color: 'rgba(73, 220, 244, 1)',
                width: 2
              }
            },
            axisLabel: {
              show: false
            },
            pointer: { //仪表盘指针
              show: 0,
            },
            detail: {
              show: 0,
            },
          },
          {
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['30%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                formatter: function (params) {
                  return '{count|' + params.name + '}\n{name|' + params.value + '}'
                },
                rich: {
                  count: {
                    color: '#C5D0D4',
                    fontSize: '14',
                    lineHeight: '30'
                  },
                  name: {
                    color: '#EF556A',
                    fontSize: '28',
                    lineHeight: '30'
                  }
                }
              }
            },
            labelLine: {
              show: false
            },
            data: this.chartArr
          }
        ]
      }
      option && this.chart.setOption(option)
      window.addEventListener("resize", () => {
        this.chart.resize()
      })
      this.timerFun(this.chart)
      this.chart.on('mouseover', params => {
        clearInterval(this.todayInterval)
        if (this.dataIndex !== params.dataIndex) {
          this.chart.dispatchAction({
            type: 'downplay',
            dataIndex: this.dataIndex
          })
        }
        this.dataIndex = params.dataIndex
        // this.chart.dispatchAction({
        //   type: 'highlight',
        //   dataIndex: this.dataIndex
        // })
      })
      this.chart.on('mouseout', () => {
        // this.timer= setInterval(looper(), 3000);
        this.timerFun(this.chart)
      })
    },
    timerFun (chartdata) {
      this.timeInterval = setInterval(() => {
        this.chartArr.length === this.dataIndex + 1 ? this.dataIndex = 0 : this.dataIndex++
        chartdata.dispatchAction({
          type: 'downplay',
          dataIndex: this.dataIndex === 0 ? this.chartArr.length - 1 : this.dataIndex - 1
        })

        chartdata.dispatchAction({
          type: 'highlight',

          dataIndex: this.dataIndex
        })
      }, 2000)
    }
  },
  destroyed () {
    clearInterval(this.todayInterval)
  }
};
</script>

<style scoped lang="scss">
.alarm_today {
  width: 493px;
  height: 258px;
  background: url("../../../../assets/smoke/right_top.png") no-repeat;
  background-size: 100% 100%;
  margin-top: -5px;
  padding-top: 1px;
  .smoke_title {
    margin-top: 35px;
  }
  #today_pie {
    margin-top: 10px;
    width: 100%;
    height: 180px;
  }
}
</style>
