<template>
  <div class='alarm_device'>
    <p class="smoke_title">近一周设备告警统计</p>
    <div id="device_bar"></div>
  </div>
</template>

<script>
import { getWeekAlarm } from '@/api'
export default {
  data () {
    return {
      timeList: [],
      sensorList: [],
      cameraList: []
    };
  },
  created () {

  },
  mounted () {

    this.init()
  },
  methods: {
    init () {
      this.timeList = []
      this.sensorList = []
      this.cameraList = []
      getWeekAlarm().then(res => {
        const arr = JSON.parse(JSON.stringify(res.data))
        let timeArr = []
        arr.map(el => {
          timeArr.push(this.$moment(el.alarmTime).format('MM-DD'))
          if (el.deviceType === 1) {
            this.sensorList.push(el.alarmNum)
          } else if (el.deviceType === 2) {
            this.cameraList.push(el.alarmNum)
          }
        })
        this.timeList = [...new Set(timeArr)]
        this.$nextTick(() => {
          this.drawEcharts()
        })
      })
    },
    drawEcharts () {
      const chart = this.$echarts.init(document.getElementById('device_bar'))
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          textStyle: {
            color: '#00FFFF'
          },
          right: '2%',
          top: '5%'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: this.timeList,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff"
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff"
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(0, 255, 255, 0.58)",
              type: "dotted"
            }
          }
        },
        series: [
          {
            name: '传感器',
            type: 'bar',
            // emphasis: {
            //   focus: 'series'
            // },
            data: this.sensorList,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(125, 63, 230, 1)'
              }, {
                offset: 0.8,
                color: 'rgba(179, 135, 253, 1)'
              }], false)
            }
          },
          {
            name: '摄像头',
            type: 'bar',
            data: this.cameraList,
            // emphasis: {
            //   focus: 'series'
            // },
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(36, 240, 228, 1)'
              }, {
                offset: 0.8,
                color: 'rgba(22, 133, 207, 1)'
              }], false)
            }
          }
        ]
      }
      option && chart.setOption(option)
      window.addEventListener("resize", () => {
        chart.resize()
      })
    }
  }
};
</script>

<style scoped lang="scss">
.alarm_device {
  width: 492px;
  height: 356px;
  background: url("../../../../assets/smoke/right_center.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 1px;
  .smoke_title {
    margin-top: 34px;
  }
  #device_bar {
    margin-top: 10px;
    width: 100%;
    height: 280px;
  }
}
</style>
