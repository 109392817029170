<template>
  <div class='six_months'>
    <div id="six_months"></div>
  </div>
</template>

<script>
import { getHalfYear } from '@/api'
export default {
  data () {
    return {
      chart: null,
      lineList: [],
      timeList: []
    };
  },
  created () {

  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      getHalfYear().then(res => {
        let onInfo = {},
          newData = []
        this.timeList = []
        for (let i = 0; i < res.data.length; i++) {
          this.timeList.push(res.data[i].time)
          const item = res.data[i];
          if (!onInfo[item.info]) {
            newData.push({
              unitType: item.unitType,
              type: 'line',
              stack: 'Total',
              name: item.info,
              data: [item.alarmNum]
            });
            onInfo[item.info] = item;
          } else {
            for (let j = 0; j < newData.length; j++) {
              const single = newData[j];
              if (single.name == item.info) {
                single.data.push(item.alarmNum);
                break;
              }
            }
          }
        }
        this.lineList = newData.filter(el => el.name !== '全部')
        this.lineList.map(el => {
          el.data.sort((a, b) => {
            return a.time > b.time ? 1 : -1
          })
        })
        this.$nextTick(() => {
          this.drawEcharts()
        })
      })
    },
    drawEcharts () {
      this.chart = this.$echarts.init(document.getElementById('six_months'))
      const timeArr = [...new Set(this.timeList)].sort((a, b) => {
        return a > b ? 1 : -1
      })
      let legendList = []
      // let xData = []
      this.lineList.map(el => {
        legendList.push(el.name)
        el.data = el.data.reverse()
      })
      const option = {
        legend: {
          data: legendList,
          textStyle: {
            color: '#fff'
          },
          right: '2%',
          icon: "rect",
          itemWidth: 15,
          itemHeight: 2
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: timeArr,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff"
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff"
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(0, 255, 255, 0.58)",
              type: "dotted"
            }
          }
        },
        series: this.lineList
      }
      option && this.chart.setOption(option)
      window.addEventListener("resize", () => {
        this.chart.resize()
      })
    }
  }
};
</script>

<style scoped lang="scss">
.six_months {
  width: 480px;
  #six_months {
    margin-top: 15px;
    width: 100%;
    height: 280px;
  }
}
</style>
