<template>
  <div class='smoke'>
    <smoke-header />
    <div class="smoke_content">
      <div>
        <left-title />
        <div class="left_center">
          <alarm-statistics />
          <smoke-map />
        </div>
        <alarm />
      </div>
      <div>
        <alarm-today />
        <alarm-device />
        <smoke-video />
      </div>
    </div>
  </div>
</template>

<script>
import SmokeHeader from './components/header'
import LeftTitle from './components/left/leftTitle.vue'
import AlarmStatistics from './components/left/alarmStatistics.vue'
import SmokeMap from './components/left/map.vue'
import Alarm from './components/left/alarm.vue'
import AlarmToday from './components/right/alarmToday.vue'
import AlarmDevice from './components/right/alarmDevice.vue'
import SmokeVideo from './components/right/video.vue'
export default {
  components: { SmokeHeader, LeftTitle, AlarmStatistics, Alarm, AlarmToday, AlarmDevice, SmokeVideo, SmokeMap },
  data () {
    return {

    };
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.smoke {
  width: 100vw;
  height: 100%;
  background: #03101a;
  .smoke_content {
    width: 100%;
    padding: 0 32px 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .left_center {
      display: flex;
    }
  }
}
</style>
