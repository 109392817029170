<template>
  <div class='alarm_statistics'>
    <p class="smoke_title">最近告警统计</p>
    <div class="alarmStatistics_content">
      <el-row class="alarmStatistics_title">
        <el-col :span="4">类别</el-col>
        <el-col :span="7">单位</el-col>
        <el-col :span="6">点位</el-col>
        <el-col :span="7">时间</el-col>
      </el-row>
      <div class="swiper swiper_alarmStatistics" v-if="alarmStatisticsList.length>0">
        <div class="swiper-wrapper">
          <el-row v-for="(el, index) in alarmStatisticsList" class="swiper-slide" :key="index">
            <el-col :span="4">{{el.deviceType === 2?'摄像头':el.deviceType === 1?'烟感':''}}</el-col>
            <el-col :span="7">
              <p>{{el.unit}}</p>
            </el-col>
            <el-col :span="6">
              <p>{{el.location}}</p>
            </el-col>
            <el-col :span="7">
              <p>{{el.gmtCreate}}</p>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAlarmStatistics } from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default {
  data () {
    return {
      alarmStatisticsList: []
    }
  },
  created () {

  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      getAlarmStatistics().then(res => {
        this.alarmStatisticsList = res.data
        this.initSwiper()
      })
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.alarmStatisticsList && this.alarmStatisticsList.length > 8) {
          new Swiper('.swiper_alarmStatistics', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.alarmStatisticsList && this.alarmStatisticsList.length <= 8) {
          new Swiper('.swiper_alarmStatistics', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.alarm_statistics {
  width: 530px;
  height: 457px;
  background: url("../../../../assets/smoke/left_top.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 22px;
  text-align: center;
  padding-top: 1px;
  .smoke_title {
    margin-top: 35px;
  }
  .alarmStatistics_content {
    padding: 29px 14px 0 12px;
    background: rgba(4, 32, 49, 0.4);
    .alarmStatistics_title {
      padding: 8px 0;
      font-size: 16px;
      display: flex;
      color: #c5d0d4;
      background: rgba(236, 244, 255, 0.1);
      .el-col {
        float: none;
      }
    }
    .swiper_alarmStatistics {
      overflow: hidden;
      height: 310px;
      margin-top: 20px;
      .swiper-slide {
        display: flex;
        margin-bottom: 27px;
        height: 15px;
        .el-col {
          float: none;
          font-size: 14px;
          color: #c5d0d4;
          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
