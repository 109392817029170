<template>
  <div class='smoke_map'>
    <div id='smokeMap'>
    </div>
  </div>
</template>

<script>
import AMap from 'AMap'
import AMapUI from 'AMapUI'
import { mapGetters } from 'vuex'
import { getLocation } from '@/api'
// import Vue from 'vue'

export default {
  data () {
    return {
      markList: [],
      map: null
    };
  },
  created () {

  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapGetters(['name'])
  },
  methods: {
    init () {
      getLocation().then(res => {
        this.markList = res.data
        this.$nextTick(() => {
          this.loadMap()
        })
      })
      // getItem().then(res => {
      //   console.log('res :>> ', res);
      // })
    },
    loadMap () {
      this.map = new AMap.Map('smokeMap', {
        resizeEnable: true,
        viewMode: '3D',
        mapStyle: 'amap://styles/darkblue',
        pitch: 30,
        // // center: [108.909759, 34.412745],
        // // center: [121.045332, 31.19884],
        // zoom: 8.8
      })

      this.loadMapData()
    },
    loadMapData () {
      const _this = this
      AMap.plugin('AMap.Geocoder', function () {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          // city: '010'
        })

        geocoder.getLocation(_this.name, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // result中对应详细地理坐标信息
            AMapUI.loadUI(['geo/DistrictExplorer'], function (DistrictExplorer) {

              //创建一个实例
              var districtExplorer = new DistrictExplorer({
                map: _this.map
              })
              var adcode = result.geocodes[0].adcode
              districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
                //更新地图视野
                _this.map.setBounds(areaNode.getBounds(), null, null, true);
                //清除已有的绘制内容
                districtExplorer.clearFeaturePolygons();
                //绘制子区域
                districtExplorer.renderSubFeatures(areaNode, function () {
                  return {
                    cursor: 'default',
                    bubble: true,
                    strokeColor: '#137CC4', //线颜色
                    strokeOpacity: 1, //线透明度
                    strokeWeight: 1, //线宽
                    fillColor: '#0B1B27', //填充色
                    fillOpacity: 0.35, //填充透明度
                    strokeStyle: 'dashed',
                  }
                })
                //绘制父区域
                districtExplorer.renderParentFeature(areaNode, {
                  cursor: 'default',
                  bubble: true,
                  strokeColor: '#137CC4', //线颜色
                  strokeOpacity: 0.6, //线透明度
                  strokeStyle: 'dashed',
                  strokeWeight: 1, //线宽
                  fillColor: "#0B1B27", //填充色
                  fillOpacity: 0.35, //填充透明度
                })
              })
              _this.map.setFitView(districtExplorer.getAllFeaturePolygons());
            })
          }
        })
      })
      this.addMark()
    },
    addMark () {
      const markerList = []
      const _this = this
      this.markList.forEach(el => {
        el.temp = new AMap.Marker({
          map: this.map,
          // icon: new AMap.Icon({
          //   // image: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
          //   size: new AMap.Size(52, 52),  //图标大小
          //   imageSize: new AMap.Size(26, 26)
          // }),
          position: [el.longitude, el.latitude],
          title: el.unit,
        })
        el.temp.content = `<div class="dot_item">
          <div>
            <div class="title">
            <h3>
            ${el.unit}
            </h3>
            </div>
          <div>
          <ul>
            <li>
              <h4>${el.deviceQuantity ? el.deviceQuantity : 0}</h4>
              <p>设备数量</p>
            </li>
            <li>
              <h4>${el.alarmCount ? el.alarmCount : 0}</h4>
              <p>告警次数</p>
            </li>
          </ul>
        </div>
        `
        AMap.event.addListener(el.temp, 'mouseover', function () {
          // console.log(e.target.getExtData())
          _this.dotItem(el.temp)
        })
        markerList.push(el.temp)
      })
      this.map.setFitView(markerList)
    },
    // 显示信息
    dotItem (val) {
      var infoWindow = new AMap.InfoWindow({
        // position: [val.longitude, val.latitude],
        offset: new AMap.Pixel(70, -30)
      })
      // const component = new InfoWindowContent().$mount()
      infoWindow.setContent(val.content)
      // // document.getElementById('infoimg').src = '@/assets/position.png'
      infoWindow.open(this.map, val.getPosition())
    }
  }
};
</script>

<style scoped lang="scss">
.smoke_map {
  flex: 1;
  #smokeMap {
    width: 100%;
    height: 100%;
  }
}
</style>
