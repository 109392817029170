<template>
  <div class='smoke_header'>
    <p>{{name}}违禁吸烟管理平台</p>
    <span>{{$moment(time).format('YYYY-MM-DD HH:mm:ss')}}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      time: null,
      interval: null
    };
  },
  created () {
    this.interval = setInterval(() => this.time = new Date(), 1000)
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['name'])
  },
  methods: {

  },
  destroyed () {
    clearInterval(this.interval)
  }
};
</script>

<style scoped lang="scss">
.smoke_header {
  position: relative;
  height: 76px;
  line-height: 76px;
  width: 100%;
  background: url("../../../../assets/smoke/head.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  p {
    font-size: 46px;
    font-weight: 600;
    color: #e8effb;
    background: linear-gradient(0deg, #b2ccfc 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  span {
    font-size: 18px;
    color: #e8effb;
    font-weight: 600;
    position: absolute;
    top: 0;
    right: 10px;
  }
}
</style>
