<template>
  <div class='smoke_video'>
    <p class="smoke_title">视频画面</p>
    <div class="video_content" v-if="activeVideo">
      <video :src="`https://smoke.terabits.cn${activeVideo}`" id="myvideoback" width="100%" height="100%" controls autoPlay muted>
      </video>
    </div>
  </div>
</template>

<script>
import { getVideo } from '@/api'
// import videojs from 'video.js'
export default {
  data () {
    return {
      activeVideo: null,
      videoIndex: 0,
      videoList: [],
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      getVideo().then(res => {
        // console.log('res :>> ', res);
        this.videoList = res.data
        this.activeVideo = res.data[0]
        this.$nextTick(() => {
          this.playBack()
        })
      })
    },
    playBack () {
      let myvid = document.getElementById('myvideoback');
      // let activeVideo = 0
      let index = this.videoIndex
      // debugger
      let videoPlay = myvid.play()
      videoPlay.then(() => {
      }).catch((err) => {
        console.log(err)
        console.log("不允许自动播放")
        myvid.muted = true;
        myvid.play();
      });
      const _this = this
      myvid.addEventListener('ended', function () {
        // update the new active video index
        index += 1
        if (index === _this.videoList.length - 1) {
          index = 0
        }
        _this.activeVideo = _this.videoList[index],
          _this.videoIndex = index

        setTimeout(function () {

          myvid.play();

        }, 200);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.smoke_video {
  width: 492px;
  height: 370px;
  background: url("../../../../assets/smoke/right_bottom.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 1px;
  .smoke_title {
    margin-top: 34px;
  }
  .video_content {
    width: 455px;
    height: 275px;
    margin: 20px auto 0;
    background: #0e102c;
  }
}
</style>
