<template>
  <div class='scene_alarm'>
    <div class="percentage" v-if="sceneData.length>0">
      <div>
        <h3>{{sceneData[sceneIndex].value}}</h3>
        <p>{{sceneData[sceneIndex].name}}</p>
      </div>
      <p>{{(sceneData[sceneIndex].value / this.sumTotal * 100).toFixed(2)}}%</p>
    </div>
    <div id="scene_pie"></div>
  </div>
</template>

<script>
import { getSceneAlarm } from '@/api'
export default {
  data () {
    return {
      // data数据
      sceneData: [],
      maxNum: 0,
      sceneInterval: null,
      sceneIndex: 0,
      sumTotal: 0,
    }
  },
  created () {

  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.sceneData = []
      getSceneAlarm().then(res => {
        this.sumTotal = res.data.reduce((prev, curr) => {
          return prev + curr.alarmNum
        }, 0)
        res.data.map(el => {
          this.maxNum = this.maxNum > el.alarmNum ? this.maxNum : el.alarmNum
          if (el.info !== '全部') {
            this.sceneData.push({
              name: el.info,
              value: el.alarmNum
            })
          }
        })
        this.$nextTick(() => {
          this.drawEcharts()
          this.sceneTimer()
        })
      })
    },
    drawEcharts () {
      const myChart = this.$echarts.init(document.getElementById("scene_pie"))
      // let selectedIndex = '';
      // const _this = this
      // let isSelected;
      // let isHovered;
      // let startRatio;
      // let endRatio;
      // let k;
      // let i;
      // let hoveredIndex = '';
      // 第二个参数控制饼图的粗细
      let option = this.getPie3D(this.sceneData, 0.59);
      myChart.setOption(option);
      // myChart.on('mouseover', function (params) {
      //   // 准备重新渲染扇形所需的参数
      //   // 如果触发 mouseover 的扇形当前已高亮，则不做操作
      //   if (hoveredIndex === params.seriesIndex) {
      //     return;

      //     // 否则进行高亮及必要的取消高亮操作
      //   } else {
      //     // 如果当前有高亮的扇形，取消其高亮状态（对 option 更新）
      //     if (hoveredIndex !== '') {
      //       // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 false。
      //       isSelected = option.series[hoveredIndex].pieStatus.selected;
      //       isHovered = false;
      //       startRatio = option.series[hoveredIndex].pieData.startRatio;
      //       endRatio = option.series[hoveredIndex].pieData.endRatio;
      //       k = option.series[hoveredIndex].pieStatus.k;
      //       i = option.series[hoveredIndex].pieData.value === option.series[0].pieData.value ? 35 : 10;
      //       // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
      //       option.series[hoveredIndex].parametricEquation = _this.getParametricEquation(
      //         startRatio,
      //         endRatio,
      //         isSelected,
      //         isHovered,
      //         k,
      //         i
      //       );
      //       option.series[hoveredIndex].pieStatus.hovered = isHovered;

      //       // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
      //       hoveredIndex = '';
      //     }

      //     // 如果触发 mouseover 的扇形不是透明圆环，将其高亮（对 option 更新）
      //     if (params.seriesName !== 'mouseoutSeries') {
      //       // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
      //       isSelected = option.series[params.seriesIndex].pieStatus.selected;
      //       isHovered = true;
      //       startRatio = option.series[params.seriesIndex].pieData.startRatio;
      //       endRatio = option.series[params.seriesIndex].pieData.endRatio;
      //       k = option.series[params.seriesIndex].pieStatus.k;

      //       // 对当前点击的扇形，执行高亮操作（对 option 更新）
      //       option.series[params.seriesIndex].parametricEquation = _this.getParametricEquation(
      //         startRatio,
      //         endRatio,
      //         isSelected,
      //         isHovered,
      //         k,
      //         option.series[params.seriesIndex].pieData.value + 5
      //       );
      //       option.series[params.seriesIndex].pieStatus.hovered = isHovered;

      //       // 记录上次高亮的扇形对应的系列号 seriesIndex
      //       hoveredIndex = params.seriesIndex;
      //     }

      //     // 使用更新后的 option，渲染图表
      //     myChart.setOption(option);
      //   }
      // });

      // // 修正取消高亮失败的 bug
      // myChart.on('globalout', function () {
      //   if (hoveredIndex !== '') {
      //     // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
      //     isSelected = option.series[hoveredIndex].pieStatus.selected;
      //     isHovered = false;
      //     k = option.series[hoveredIndex].pieStatus.k;
      //     startRatio = option.series[hoveredIndex].pieData.startRatio;
      //     endRatio = option.series[hoveredIndex].pieData.endRatio;
      //     // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
      //     i = option.series[hoveredIndex].pieData.value === option.series[0].pieData.value ? 35 : 10;
      //     option.series[hoveredIndex].parametricEquation = _this.getParametricEquation(
      //       startRatio,
      //       endRatio,
      //       isSelected,
      //       isHovered,
      //       k,
      //       i
      //     );
      //     option.series[hoveredIndex].pieStatus.hovered = isHovered;

      //     // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
      //     hoveredIndex = '';
      //   }

      //   // 使用更新后的 option，渲染图表
      //   myChart.setOption(option);
      // });
    },
    getParametricEquation (startRatio, endRatio, isSelected, isHovered, k, h) {
      // 计算
      const midRatio = (startRatio + endRatio) / 2;

      const startRadian = startRatio * Math.PI * 2;
      const endRadian = endRatio * Math.PI * 2;
      const midRadian = midRatio * Math.PI * 2;

      // 如果只有一个扇形，则不实现选中效果。
      if (startRatio === 0 && endRatio === 1) {
        // eslint-disable-next-line no-param-reassign
        isSelected = false;
      }

      // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
      // eslint-disable-next-line no-param-reassign
      k = typeof k !== 'undefined' ? k : 1 / 3;

      // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
      const offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
      const offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

      // 计算高亮效果的放大比例（未高亮，则比例为 1）
      const hoverRate = isHovered ? 1.05 : 1;

      // 返回曲面参数方程
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32,
        },

        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20,
        },

        x (u, v) {
          if (u < startRadian) {
            return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
          }
          if (u > endRadian) {
            return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
          }
          return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
        },

        y (u, v) {
          if (u < startRadian) {
            return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
          }
          if (u > endRadian) {
            return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
          }
          return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
        },

        z (u, v) {
          if (u < -Math.PI * 0.5) {
            return Math.sin(u);
          }
          if (u > Math.PI * 2.5) {
            return Math.sin(u) * h * 0.1;
          }
          // 当前图形的高度是Z根据h（每个value的值决定的）
          return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
        },
      };
    },
    getPie3D (pieData, internalDiameterRatio) {
      const series = [];
      // 总和
      let sumValue = 0;
      let startValue = 0;
      let endValue = 0;
      const legendData = [];
      const k =
        typeof internalDiameterRatio !== 'undefined'
          ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
          : 1 / 3;

      // 为每一个饼图数据，生成一个 series-surface 配置
      for (let i = 0; i < pieData.length; i += 1) {
        sumValue += pieData[i].value;

        const seriesItem = {
          name: typeof pieData[i].name === 'undefined' ? `series${i}` : pieData[i].name,
          type: 'surface',
          parametric: true,
          wireframe: {
            show: false,
          },
          pieData: pieData[i],
          pieStatus: {
            selected: false,
            hovered: false,
            k,
          },
        };

        if (typeof pieData[i].itemStyle !== 'undefined') {
          const { itemStyle } = pieData[i];

          // eslint-disable-next-line no-unused-expressions
          typeof pieData[i].itemStyle.color !== 'undefined' ? (itemStyle.color = pieData[i].itemStyle.color) : null;
          // eslint-disable-next-line no-unused-expressions
          typeof pieData[i].itemStyle.opacity !== 'undefined'
            ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
            : null;

          seriesItem.itemStyle = itemStyle;
        }
        series.push(seriesItem);
      }
      // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
      // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
      for (let i = 0; i < series.length; i += 1) {
        endValue = startValue + series[i].pieData.value;

        series[i].pieData.startRatio = startValue / sumValue;
        series[i].pieData.endRatio = endValue / sumValue;
        series[i].parametricEquation = this.getParametricEquation(
          series[i].pieData.startRatio,
          series[i].pieData.endRatio,
          false,
          false,
          k,
          // 我这里做了一个处理，使除了第一个之外的值都是10
          series[i].pieData.value === this.maxNum ? 20 : 10
        );

        startValue = endValue;

        legendData.push(series[i].name);
      }

      // 准备待返回的配置项，把准备好的 legendData、series 传入。
      const option = {
        // animation: false,
        tooltip: {
          formatter: (params) => {
            if (params.seriesName !== 'mouseoutSeries') {
              return `${params.seriesName
                }<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color
                };"></span>${option.series[params.seriesIndex].pieData.value}`;
            }
            return '';
          },
        },
        legend: {
          icon: "rect",
          itemWidth: 15,
          itemHeight: 10,
          bottom: 0,
          textStyle: {
            color: '#fff'
          }
        },
        xAxis3D: {
          min: -1,
          max: 1,
        },
        yAxis3D: {
          min: -1,
          max: 1,
        },
        zAxis3D: {
          min: -1,
          max: 1,
        },
        grid3D: {
          show: false,
          boxHeight: 15,
          top: '-10%',
          viewControl: {
            alpha: 35,
            // beta: 30,
            rotateSensitivity: 1,
            zoomSensitivity: 0,
            panSensitivity: 0,
            autoRotate: true,
            distance: 160,
          },
          // 后处理特效可以为画面添加高光、景深、环境光遮蔽（SSAO）、调色等效果。可以让整个画面更富有质感。
          postEffect: {
            // 配置这项会出现锯齿，请自己去查看官方配置有办法解决
            enable: false,
            bloom: {
              enable: true,
              bloomIntensity: 0.1,
            },
            SSAO: {
              enable: true,
              quality: 'medium',
              radius: 2,
            },
            // temporalSuperSampling: {
            //   enable: true,
            // },
          },
        },
        series,
      };
      return option;
    },
    sceneTimer () {
      this.sceneInterval = setInterval(() => {
        if (this.sceneIndex === this.sceneData.length - 1) {
          this.sceneIndex = 0
        } else {
          this.sceneIndex++
        }
      }, 5000)
    }

  },
  destroyed () {
    clearInterval(this.sceneInterval)
  }
};
</script>

<style scoped lang="scss">
.scene_alarm {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 45px;
  // margin: 0 auto;
  .percentage {
    width: 172px;
    height: 75px;
    background: url("../../../../assets/smoke/pie_title.png") no-repeat;
    background-size: 100% 100%;
    margin: 18px 0 6px;
    display: flex;
    padding: 19px 26px 0;
    box-sizing: border-box;
    justify-content: space-between;
    > div {
      text-align: center;
      h3 {
        font-size: 18px;
        font-weight: bold;
        color: #2380ff;
      }
      p {
        font-size: 14px;
        // margin-top: 11px;
        color: #ffffff;
        line-height: 20px;
      }
    }
    > p {
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }
  }
  #scene_pie {
    width: 100%;
    height: 200px;
  }
}
</style>
