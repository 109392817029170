<template>
  <div class='unit_alarm'>
    <div class="title">
      <p class="smoke_title">单位告警排行</p>
      <p class="smoke_title">场景单位告警分布</p>
      <p class="smoke_title">近半年场景告警分布</p>
    </div>
    <div class="content">
      <alarm-list />
      <scene-alarm />
      <six-months />
    </div>
  </div>
</template>

<script>
import AlarmList from './alarmList.vue'
import SceneAlarm from './sceneAlarm.vue'
import SixMonths from './sixMonths.vue'
export default {
  components: { AlarmList, SceneAlarm, SixMonths },
  data () {
    return {

    };
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.unit_alarm {
  width: 1348px;
  height: 370px;
  background: url("../../../../assets/smoke/left_bottom.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 1px;
  .title {
    display: flex;
    justify-content: space-around;
    margin-top: 34px;
    .smoke_title {
      width: 180px;
    }
  }
  .content {
    display: flex;
  }
}
</style>
